import { createContext, useContext, useEffect, useState, useRef } from 'react';
import { UserContext } from '123rf-ui-core/contexts';
import PropTypes from 'prop-types';

// APIs
import getFreePlusPromoUse from 'api/queries/getFreePlusPromoUse';

// Configs
import { PAGE_FOLDERS } from 'config/constants';
import { COUNTRIES_WITHOUT_AUTORENEWAL, COUNTRIES_WITHOUT_PLUS_FREE_TRIAL } from 'config/products';

// Pre-defined Components
import { GoogleTagManagerContext } from 'contexts/GoogleTagManager';
import { WindowDimensionContext } from 'contexts/WindowDimension';

export const ProductDetailsContext = createContext();

export default function useProductDetails(props) {
    const { products, CommonDetails } = props;
    const GTM = useContext(GoogleTagManagerContext);
    const { User } = useContext(UserContext);
    const WindowDimension = useContext(WindowDimensionContext);
    const { lang, countryOrigin, pageType } = CommonDetails;
    const { a2mCountries, initializedGTM, contentGroup1, statusSessionId, pushGTMData } = GTM;
    const { userIsLoggedIn } = User;

    const hasNoFreeTrial =  COUNTRIES_WITHOUT_AUTORENEWAL.includes(countryOrigin) || COUNTRIES_WITHOUT_PLUS_FREE_TRIAL.includes(countryOrigin);
    const [showPlusFreeTrial, setShowPlusFreeTrial] = useState(
        !hasNoFreeTrial,
    );
    const [isPlusFreeTrialSelected, setIsPlusFreeTrialSelected] = useState(false);

    const refCreditPacksCanBuy = useRef();

    useEffect(() => {
        if (initializedGTM) {
            pushGTMData({
                event: 'PricingPageLoaded',
                ...statusSessionId,
                language: lang,
                country: countryOrigin,
                ...(userIsLoggedIn && a2mCountries.includes(countryOrigin) && { allowA2M: '1' }),
                content_group_1: contentGroup1.pricingPage,
            });
        }
    }, [initializedGTM]);

    useEffect(() => {
        if (
            !hasNoFreeTrial &&
            userIsLoggedIn &&
            pageType !== PAGE_FOLDERS.enterprise
        ) {
            getFreePlusPromoUse().then((response) =>
                setShowPlusFreeTrial(!response?.promo_use || false),
            );
        }
    }, [countryOrigin, userIsLoggedIn, pageType]);

    return {
        products,

        /* Begin - Context Details */
        CommonDetails,
        GTM,
        User,
        WindowDimension,
        /* End - Context Details */

        /* Begin - States */
        showPlusFreeTrial,
        isPlusFreeTrialSelected,
        setIsPlusFreeTrialSelected,
        /* End - States */

        /* Begin - Refs */
        refCreditPacksCanBuy,
        /* End - Refs */
    };
}

export const ProductDetailsProvider = (props) => {
    const { children } = props;
    return (
        <ProductDetailsContext.Provider value={useProductDetails(props)}>
            {children}
        </ProductDetailsContext.Provider>
    );
};

ProductDetailsProvider.propTypes = {
    children: PropTypes.node.isRequired,
};
