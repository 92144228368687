import PropTypes from 'prop-types';
import intl from 'react-intl-universal';

// Pre-defined Components
import { COUNTRIES_WITHOUT_INSTALLMENT } from 'config/products';

// Sub Component
import PlanDetailsItem from 'components/Products/NewAffordablePlans/Shared/PlanDetailsItem';

const SubscriptionPlanDetails = (props) => {
    const { CommonDetails, isYearlyPlan } = props;
    const { locale, countryOrigin: co } = CommonDetails;
    const countryOrigin = locale?.country?.code || co;

    return (
        <ul className="SubscriptionPlanDetails__wrapper">
            <PlanDetailsItem item={intl.getHTML('PRODUCTS.SubscriptionPremiumCollection')} />
            {(isYearlyPlan || !COUNTRIES_WITHOUT_INSTALLMENT.includes(countryOrigin)) && (
                <PlanDetailsItem item={intl.getHTML('PRODUCTS.SubscriptionUnusedDownloads')} />
            )}
            <PlanDetailsItem item={intl.getHTML('PRODUCTS.SubscriptionStandardLicense')} type="link"/>
            <PlanDetailsItem item={intl.getHTML('PRODUCTS.SubscriptionDownloadImages')} />

            <style jsx>
                {`
                    .SubscriptionPlanDetails {
                        &__wrapper {
                            width: 100%;
                            height: auto;
                            display: flex;
                            flex-direction: column;
                            gap: 4px;
                            margin-top: 16px;
                        }
                    }
                `}
            </style>
        </ul>
    );
};

SubscriptionPlanDetails.propTypes = {
    CommonDetails: PropTypes.objectOf(PropTypes.shape).isRequired,
    isYearlyPlan: PropTypes.bool.isRequired,
};

export default SubscriptionPlanDetails;
