import { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import { TextLink } from '123rf-ui-core/components';

// Pre-defined Components
import {
    CREDITS_ONLY_PAGE_ORIGIN as creditsPO,
    PLAN_TYPES,
    PAY_IN_LOCAL_CURRENCY,
    PREMIUM_ONLY_PAGE_ORIGIN as premiumOnly,
} from 'config/products';
import getUserActivePlusPlan from 'api/queries/getUserActivePlusPlan';

// Sub Components
import PricingCard from './Shared/PricingCard';
import SubscriptionPlan from './SubscriptionPlan';
import CreditPack from './CreditPack';
import PlusPlan from './PlusPlan';
import PlusBusinessPlan from './PlusBusinessPlan';
import PlanType from './PlanType';

const ContactMeModal = dynamic(() => import('components/Shared/ContactMe'));

const UILoadingOverlay = dynamic(() => import('components/Shared/UILoadingOverlay'), {
    ssr: false,
});

const NotificationToast = dynamic(() =>
    import('123rf-ui-core/components').then((mod) => mod.NotificationToast),
);

function NewAffordablePlans(props) {
    const { CommonDetails, WindowDimension, User } = props;
    const { lang, pageOrigin, countryOrigin } = CommonDetails;
    const {
        isTabletView,
        isTabletLGView,
        isDesktopView,
        isDesktopXLView,
        isDesktopXXLView,
        isMobileView,
    } = WindowDimension;
    const { userBalance, userId } = User;
    const isLangKR = lang === 'kr';

    const NOTIFICATION_TYPES = {
        SUCCESS: 'success',
        WARNING: 'warning',
        ERROR: 'error',
    };

    const NOTIFICATION_MESSAGES = {
        RATE_LIMITED: 'SHARED.FormSubmitRateLimited',
        CAPTCHA_EXPIRED: 'SHARED.FormSubmitCaptchaExpired',
        GENERAL_ERROR: 'SHARED.FormSubmitErrMsg',
        SUCCESS: 'PRODUCTS.RequestFormSubmitSuccess',
    };

    const HTTP_STATUS = {
        TOO_MANY_REQUESTS: 429,
        BAD_REQUEST: 400,
    }

    const [isPlusBusinessActive, setIsPlusBusinessActive] = useState(false);

    const [showContactMeModal, setShowContactMeModal] = useState(false);

    const hideOtherPlans = creditsPO.includes(pageOrigin) || premiumOnly.includes(pageOrigin);

    const [notification, setNotification] = useState({ type: NOTIFICATION_TYPES.SUCCESS, text: '' });
    const [showNotification, setShowNotification] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleNotification = (response) => {
        let type = NOTIFICATION_TYPES.SUCCESS;
        let messageKey = NOTIFICATION_MESSAGES.SUCCESS;

        if (response?.data?.error) {
            if (response.status === HTTP_STATUS.TOO_MANY_REQUESTS) {
                type = NOTIFICATION_TYPES.WARNING;
                messageKey = NOTIFICATION_MESSAGES.RATE_LIMITED;
            } else if (response.status === HTTP_STATUS.BAD_REQUEST) {
                type = NOTIFICATION_TYPES.WARNING;
                messageKey = NOTIFICATION_MESSAGES.CAPTCHA_EXPIRED;
            } else {
                type = NOTIFICATION_TYPES.ERROR;
                messageKey = NOTIFICATION_MESSAGES.GENERAL_ERROR;
            }
        }

        setNotification({
            type,
            text: intl.get(messageKey),
        });
        setShowNotification(true);
    }

    useEffect(() => {
        const checkActivePlusPlan = async () => {
            if (
                userId &&
                !creditsPO.includes(pageOrigin) &&
                !premiumOnly.includes(pageOrigin) &&
                userBalance?.plus > 0
            ) {
                const activePlusPlan = await getUserActivePlusPlan(undefined, userId);
                setIsPlusBusinessActive(activePlusPlan?.is_plus_business || false);
            }
        };
        checkActivePlusPlan();
    }, []);

    return (
        <section className="NewAffordablePlans__wrapper">
            <h1 className="NewAffordablePlans__title">
                {intl.getHTML('PRODUCTS.ChoosePlan_Title')}
            </h1>
            {!hideOtherPlans && <PlanType {...props} />}

            <div className="NewAffordablePlans__subWrapper">
                {!creditsPO.includes(pageOrigin) && (
                    <>
                        { pageOrigin !== 'premium' && (
                            <PricingCard
                                flexOrder={1}
                                cardType={PLAN_TYPES.PLUS}
                                isLangKR={isLangKR}
                                isPlusBusinessActive={isPlusBusinessActive}
                                {...props}
                            >
                                {(card) => (
                                    <PlusPlan
                                        isPlusBusinessActive={isPlusBusinessActive}
                                        {...props}
                                        {...card}
                                    />
                                )}
                            </PricingCard>
                        )}
                        { pageOrigin !== 'premium' && (
                            <PricingCard
                                flexOrder={2}
                                cardType={PLAN_TYPES.PLUS_BUSINESS}
                                isLangKR={isLangKR}
                                isPlusBusinessActive={isPlusBusinessActive}
                                {...props}
                            >
                                {(card) => (
                                    <PlusBusinessPlan
                                        isPlusBusinessActive={isPlusBusinessActive}
                                        {...props}
                                        {...card}
                                    />
                                )}
                            </PricingCard>
                        )}
                        <PricingCard
                            flexOrder={3}
                            cardType={PLAN_TYPES.SUBSCRIPTION}
                            isLangKR={isLangKR}
                            {...props}
                        >
                            {(card) => <SubscriptionPlan {...props} {...card} />}
                        </PricingCard>
                    </>
                )}
                <PricingCard
                    flexOrder={4}
                    cardType={PLAN_TYPES.CREDIT}
                    isLangKR={isLangKR}
                    {...props}
                >
                    {(card) => <CreditPack {...props} {...card} />}
                </PricingCard>
            </div>
            <div className="NewAffordablePlans__contactMe">
                {PAY_IN_LOCAL_CURRENCY.includes(countryOrigin) ? (
                    intl.getHTML('PRODUCTS.ChoosePlan_PayInLocalCurrency')
                ) : (
                    <div className="NewAffordablePlans__contactMe--text">
                        {intl.getHTML('PRODUCTS.WantMoreFlexiblePlan')}{' '}
                        <TextLink
                            role='button'
                            tabIndex="0"
                            type="primary"
                            onClick={() => setShowContactMeModal(true)}
                            onKeyDown={(e) =>
                                e.key === 'Enter' && setShowContactMeModal(true)
                            }
                        >
                            {intl.getHTML('PRODUCTS.LetUsCallYou')}
                        </TextLink>
                    </div>
                )}
            </div>

            {showContactMeModal && (
                <ContactMeModal
                    showContactMeModal={showContactMeModal}
                    setShowContactMeModal={setShowContactMeModal}
                    showCloseButton
                    isDesktopView={isDesktopView}
                    isTabletView={isTabletView}
                    isMobileView={isMobileView}
                    UserInfo={User}
                    onNotification={handleNotification}
                    setLoading={setLoading}
                />
            )}
            {loading && <UILoadingOverlay />}

            {showNotification && (
                <NotificationToast
                    id="new-affordable-plan-toast"
                    className="NewAffordablePlans__toast"
                    type={notification.type}
                    text={notification.text}
                    onHidden={() => setShowNotification(false)}
                    closeBtn
                />
            )}

            <style jsx>
                {`
                    .NewAffordablePlans {
                        &__title {
                            width: 100%;
                            font-weight: 600;
                            font-size: ${isDesktopView || isTabletView ? '24px' : '16px'};
                            line-height: ${isDesktopView || isTabletView ? '2.25rem' : '1.5rem'};
                            text-align: center;
                            color: #ffffff;
                            margin-bottom: 24px;
                            z-index: 1;
                        }

                        &__subTitle {
                            width: 100%;
                            font-size: ${isDesktopView || isTabletView ? '25px' : '18px'};
                            color: #ffffff;
                            font-weight: normal;
                            line-height: ${isDesktopView ? '1.5rem' : '1.25rem'};
                            text-align: center;
                            margin-top: ${isDesktopView || isTabletView ? '5px' : '3px'};
                            padding: 0;
                            margin-bottom: 24px;
                            z-index: 1;
                        }

                        &__subWrapper {
                            flex-wrap: wrap;
                            height: auto;
                            width: ${isDesktopXXLView || isDesktopXLView
                                ? 'auto'
                                : isTabletView
                                    ? '768px'
                                    : '100%'};
                            display: flex;
                            flex-direction: ${isDesktopView || isTabletView ? 'row' : 'column'};
                            justify-content: center;
                            align-items: ${isDesktopView || isTabletView ? 'stretch' : 'center'};
                            gap: ${isDesktopView ? '8px' : isTabletView ? '24px' : '16px'};
                        }

                        &__contactMe {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 1.25rem;
                            color: #ffffff;
                            z-index: 1;
                            margin-top: ${isDesktopView
                                ? '24.5px'
                                : isTabletView
                                    ? '20px'
                                    : '16px'};

                            &--text {
                                text-align: center;
                            }
                        }


                    }
                `}
            </style>
            <style jsx global>
                {`
                    .NewAffordablePlans {
                        &__wrapper {
                            position: relative;
                            overflow: hidden;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            width: 100%;
                            background: radial-gradient(at center top, #ffd44d -50%, #0d0d0d 70%);
                            padding: ${isDesktopView || isTabletLGView
                        ? '32px 0px 32px 0px'
                        : isTabletView
                            ? '32px 15px 32px 15px'
                            : '16px 10px 16px 10px'};
                        }

                        &__commonLink {
                            color: #2298ca !important;
                            text-underline-offset: 1px !important;
                            text-decoration-line: underline !important;
                            -webkit-text-decoration-line: underline !important;
                            text-decoration-thickness: 1px !important;
                            text-underline-position: under !important;

                            &:hover {
                                color: #e0e0e0 !important;
                                cursor: pointer;
                            }
                        }

                        &__toast {
                            position: fixed;
                            width: calc(100% - 20px) !important;
                            max-width: 510px !important;
                            top: 10%;
                            left: 50%;
                            transform: translate(-50%);
                            z-index: 9999;
                        }
                    }
                `}
            </style>
        </section>
    );
}

NewAffordablePlans.propTypes = {
    CommonDetails: PropTypes.objectOf(PropTypes.shape).isRequired,
    WindowDimension: PropTypes.objectOf(PropTypes.shape).isRequired,
    User: PropTypes.objectOf(PropTypes.shape).isRequired,
};

export default NewAffordablePlans;
