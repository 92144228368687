const decodeHtmlEntity = (str) => {
    if (!str) return null;
    if (str.includes('&aacute;')) {
        return str.replaceAll('&aacute;', 'á');
    }
    return str.replace(/&#(\d+);/g, function (match, dec) {
        return String.fromCharCode(dec);
    });
};

export default decodeHtmlEntity;
