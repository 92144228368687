import cx from 'classnames';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';

// Pre-defined Components
import {
    ENTERPRISE_LANDING_ABM,
    ENTERPRISE_LANDING_IT,
    ENTERPRISE_LANDING_GDVA,
    ENTERPRISE_LANDING_EDU,
    ENTERPRISE_LANDING_HW,
    ENTERPRISE_LANDING_API,
    ENTERPRISE_LANDING_OTHERS,
} from 'config/constants';
import { PRODUCTS_IMAGES as images } from 'config/images/products';

import PlanType from './PlanType';

function NewAffordablePlansEnterprise(props) {
    const { WindowDimension } = props;
    const {
        isMobileView,
        isTabletView,
        isTabletLGView,
        isDesktopView,
        isDesktopXLView,
    } = WindowDimension;

    const clickEnterpriseThumbnail = (e, url) => {
        e.preventDefault();
        window.open(url, '_blank');
    };

    return (
        <section className="NewAffordablePlansEnterprise__wrapper">
            <h1 className="NewAffordablePlansEnterprise__title">
                {intl.getHTML('PRODUCTS.ChoosePlan_Title')}
            </h1>
            <PlanType {...props} />
            <div className="NewAffordablePlansEnterprise__enterprise">
                <div className="NewAffordablePlansEnterprise__enterprise--header">
                    <span className="NewAffordablePlansEnterprise__enterprise--header--title">
                        {intl.get('PRODUCTS.Enterprise_Title')}
                    </span>
                    <span className="NewAffordablePlansEnterprise__enterprise--header--subtitle">
                        {intl.get('PRODUCTS.Enterprise_Subtitle')}
                    </span>
                </div>

                <div className="NewAffordablePlansEnterprise__enterprise--container">
                    <div className="NewAffordablePlansEnterprise__enterprise--container--row">
                        <a
                            className={cx(
                                'NewAffordablePlansEnterprise__enterprise--container--item',
                                {
                                    'NewAffordablePlansEnterprise__enterprise--container--item-basis-3': isDesktopView,
                                    'NewAffordablePlansEnterprise__enterprise--container--item-basis-2':
                                        isTabletView || isMobileView,
                                },
                            )}
                            href={ENTERPRISE_LANDING_ABM}
                            onClick={(e) => clickEnterpriseThumbnail(e, ENTERPRISE_LANDING_ABM)}
                        >
                            <div className="NewAffordablePlansEnterprise__enterprise--container--item--description">
                                <span>{intl.get('PRODUCTS.Enterprise_Description_One')}</span>
                                <img src={images.arrowRightLine} alt="" width={24} height={24} />
                            </div>
                            <picture>
                                <source
                                    srcSet={images.newAffordablePlanEnterpriseOneWebp}
                                    type="image/webp"
                                />
                                <img
                                    src={images.newAffordablePlanEnterpriseOne}
                                    className="NewAffordablePlansEnterprise__enterprise--container--item--image"
                                    alt={intl.get('PRODUCTS.Enterprise_Description_One')}
                                    role="presentation"
                                />
                            </picture>
                        </a>
                        <a
                            className={cx(
                                'NewAffordablePlansEnterprise__enterprise--container--item',
                                {
                                    'NewAffordablePlansEnterprise__enterprise--container--item-basis-1': isDesktopView,
                                    'NewAffordablePlansEnterprise__enterprise--container--item-basis-2':
                                        isTabletView || isMobileView,
                                },
                            )}
                            href={ENTERPRISE_LANDING_IT}
                            onClick={(e) => clickEnterpriseThumbnail(e, ENTERPRISE_LANDING_IT)}
                        >
                            <div className="NewAffordablePlansEnterprise__enterprise--container--item--description">
                                <span>{intl.get('PRODUCTS.Enterprise_Description_Two')}</span>
                                <img src={images.arrowRightLine} alt="" width={24} height={24} />
                            </div>
                            <picture>
                                <source
                                    srcSet={images.newAffordablePlanEnterpriseTwoWebp}
                                    type="image/webp"
                                />
                                <img
                                    src={images.newAffordablePlanEnterpriseTwo}
                                    className="NewAffordablePlansEnterprise__enterprise--container--item--image"
                                    alt={intl.get('PRODUCTS.Enterprise_Description_Two')}
                                    role="presentation"
                                />
                            </picture>
                        </a>
                    </div>
                    <div className="NewAffordablePlansEnterprise__enterprise--container--row">
                        <a
                            className={cx(
                                'NewAffordablePlansEnterprise__enterprise--container--item',
                                {
                                    'NewAffordablePlansEnterprise__enterprise--container--item-basis-1': true,
                                },
                            )}
                            href={ENTERPRISE_LANDING_GDVA}
                            onClick={(e) => clickEnterpriseThumbnail(e, ENTERPRISE_LANDING_GDVA)}
                        >
                            <div className="NewAffordablePlansEnterprise__enterprise--container--item--description">
                                <span>{intl.get('PRODUCTS.Enterprise_Description_Three')}</span>
                                <img src={images.arrowRightLine} alt="" width={24} height={24} />
                            </div>
                            <picture>
                                <source
                                    srcSet={images.newAffordablePlanEnterpriseThreeWebp}
                                    type="image/webp"
                                />
                                <img
                                    src={images.newAffordablePlanEnterpriseThree}
                                    className="NewAffordablePlansEnterprise__enterprise--container--item--image"
                                    alt={intl.get('PRODUCTS.Enterprise_Description_Three')}
                                    role="presentation"
                                />
                            </picture>
                        </a>
                        <a
                            className={cx(
                                'NewAffordablePlansEnterprise__enterprise--container--item',
                                {
                                    'NewAffordablePlansEnterprise__enterprise--container--item-basis-1': true,
                                },
                            )}
                            href={ENTERPRISE_LANDING_EDU}
                            onClick={(e) => clickEnterpriseThumbnail(e, ENTERPRISE_LANDING_EDU)}
                        >
                            <div className="NewAffordablePlansEnterprise__enterprise--container--item--description">
                                <span>{intl.get('PRODUCTS.Enterprise_Description_Four')}</span>
                                <img src={images.arrowRightLine} alt="" width={24} height={24} />
                            </div>
                            <picture>
                                <source
                                    srcSet={images.newAffordablePlanEnterpriseFourWebp}
                                    type="image/webp"
                                />
                                <img
                                    src={images.newAffordablePlanEnterpriseFour}
                                    className="NewAffordablePlansEnterprise__enterprise--container--item--image"
                                    alt={intl.get('PRODUCTS.Enterprise_Description_Four')}
                                    role="presentation"
                                />
                            </picture>
                        </a>
                        <a
                            className={cx(
                                'NewAffordablePlansEnterprise__enterprise--container--item',
                                {
                                    'NewAffordablePlansEnterprise__enterprise--container--item-basis-1': true,
                                },
                            )}
                            href={ENTERPRISE_LANDING_HW}
                            onClick={(e) => clickEnterpriseThumbnail(e, ENTERPRISE_LANDING_HW)}
                        >
                            <div className="NewAffordablePlansEnterprise__enterprise--container--item--description">
                                <span>{intl.get('PRODUCTS.Enterprise_Description_Five')}</span>
                                <img src={images.arrowRightLine} alt="" width={24} height={24} />
                            </div>
                            <picture>
                                <source
                                    srcSet={images.newAffordablePlanEnterpriseFiveWebp}
                                    type="image/webp"
                                />
                                <img
                                    src={images.newAffordablePlanEnterpriseFive}
                                    className="NewAffordablePlansEnterprise__enterprise--container--item--image"
                                    alt={intl.get('PRODUCTS.Enterprise_Description_Five')}
                                    role="presentation"
                                />
                            </picture>
                        </a>
                    </div>
                    <div className="NewAffordablePlansEnterprise__enterprise--container--row">
                        <a
                            className={cx(
                                'NewAffordablePlansEnterprise__enterprise--container--item',
                                {
                                    'NewAffordablePlansEnterprise__enterprise--container--item-basis-1': isDesktopView,
                                    'NewAffordablePlansEnterprise__enterprise--container--item-basis-2':
                                        isTabletView || isMobileView,
                                },
                            )}
                            href={ENTERPRISE_LANDING_API}
                            onClick={(e) => clickEnterpriseThumbnail(e, ENTERPRISE_LANDING_API)}
                        >
                            <div className="NewAffordablePlansEnterprise__enterprise--container--item--description">
                                <span>{intl.get('PRODUCTS.Enterprise_Description_Six')}</span>
                                <img src={images.arrowRightLine} alt="" width={24} height={24} />
                            </div>
                            <picture>
                                <source
                                    srcSet={images.newAffordablePlanEnterpriseSixWebp}
                                    type="image/webp"
                                />
                                <img
                                    src={images.newAffordablePlanEnterpriseSix}
                                    className="NewAffordablePlansEnterprise__enterprise--container--item--image"
                                    alt={intl.get('PRODUCTS.Enterprise_Description_Six')}
                                    role="presentation"
                                />
                            </picture>
                        </a>
                        <a
                            className={cx(
                                'NewAffordablePlansEnterprise__enterprise--container--item',
                                {
                                    'NewAffordablePlansEnterprise__enterprise--container--item-basis-3': isDesktopView,
                                    'NewAffordablePlansEnterprise__enterprise--container--item-basis-2':
                                        isTabletView || isMobileView,
                                },
                            )}
                            href={ENTERPRISE_LANDING_OTHERS}
                            onClick={(e) => clickEnterpriseThumbnail(e, ENTERPRISE_LANDING_OTHERS)}
                        >
                            <div className="NewAffordablePlansEnterprise__enterprise--container--item--description">
                                <span>{intl.get('PRODUCTS.Enterprise_Description_Seven')}</span>
                                <img src={images.arrowRightLine} alt="" width={24} height={24} />
                            </div>
                            <picture>
                                <source
                                    srcSet={images.newAffordablePlanEnterpriseSevenWebp}
                                    type="image/webp"
                                />
                                <img
                                    src={images.newAffordablePlanEnterpriseSeven}
                                    className="NewAffordablePlansEnterprise__enterprise--container--item--image"
                                    alt={intl.get('PRODUCTS.Enterprise_Description_Seven')}
                                    role="presentation"
                                />
                            </picture>
                        </a>
                    </div>
                </div>
            </div>

            <style jsx>
                {`
                    .NewAffordablePlansEnterprise {
                        &__enterprise {
                            display: flex;
                            width: inherit;
                            max-width: 1000px;
                            padding: 24px;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            gap: 24px;
                            border-radius: 10px;
                            background: #ffffff;

                            &--header {
                                &--title {
                                    display: block;
                                    color: #212121;
                                    text-align: center;
                                    font-size: ${isDesktopView || isTabletView ? '24px' : '16px'};
                                    font-style: normal;
                                    font-weight: 600;
                                    line-height: ${isDesktopView || isTabletView
                                        ? '2.25rem'
                                        : '1.5rem'};
                                    margin-bottom: 8px;
                                }

                                &--subtitle {
                                    display: block;
                                    color: #212121;
                                    text-align: center;
                                    font-size: ${isDesktopView || isTabletView ? '16px' : '12px'};
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: ${isDesktopView || isTabletView
                                        ? '1.5rem'
                                        : '1.25rem'};
                                }
                            }

                            &--container {
                                display: flex;
                                flex-direction: column;
                                width: 100%;
                                gap: 16px;

                                &--row {
                                    display: flex;
                                    flex-direction: row;
                                    gap: 16px;
                                }

                                &--item {
                                    position: relative;
                                    cursor: pointer;

                                    &-basis-1 {
                                        flex-basis: 33.3%;
                                    }
                                    &-basis-2 {
                                        flex-basis: 50%;
                                    }
                                    &-basis-3 {
                                        flex-basis: calc(66.6% + 16px);
                                    }

                                    &--image {
                                        width: 100%;
                                        height: 218px;
                                        object-fit: cover;
                                        border-radius: 10px;

                                        user-drag: none;
                                        -webkit-user-drag: none;
                                        user-select: none;
                                        -moz-user-select: none;
                                        -webkit-user-select: none;
                                        -ms-user-select: none;
                                    }

                                    &--description {
                                        display: flex;
                                        position: absolute;
                                        justify-content: space-between;
                                        align-items: flex-end;
                                        flex-shrink: 0;
                                        width: ${isMobileView
                                            ? 'calc(100% - 15px)'
                                            : 'calc(100% - 28px)'};
                                        margin: ${isMobileView ? '7' : '13.5'}px;
                                        bottom: 0;
                                        gap: 5px;

                                        color: #ffffff;
                                        font-size: ${isMobileView ? '10' : '14'}px;
                                        font-style: normal;
                                        font-weight: ${isMobileView ? '600' : '700'};
                                        line-height: ${isMobileView ? 'normal' : '1.25rem'};

                                        & > span {
                                            min-height: 18px;
                                            overflow: hidden;
                                            text-overflow: ellipsis;
                                            display: -webkit-box;
                                            -webkit-line-clamp: 3;
                                            -webkit-box-orient: vertical;
                                            word-wrap: break-word;
                                            -webkit-hyphens: auto;
                                            -moz-hyphens: auto;
                                            -ms-hyphens: auto;
                                            hyphens: auto;
                                        }
                                    }
                                }
                            }
                        }

                        &__wrapper {
                            position: relative;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            min-height: ${isDesktopView || isTabletLGView
                                ? '805px'
                                : isTabletView
                                ? '885px'
                                : 'auto'};
                            width: 100%;
                            background: radial-gradient(at center top, #ffd44d -50%, #0d0d0d 70%);
                            padding: ${isDesktopView || isTabletLGView
                                ? '32px 0px 32px 0px'
                                : isTabletView
                                ? '32px 15px 32px 15px'
                                : '16px 10px 16px 10px'};
                        }

                        &__title {
                            width: 100%;
                            font-weight: 600;
                            font-size: ${isDesktopView || isTabletView ? '24px' : '16px'};
                            line-height: ${isDesktopView || isTabletView ? '2.25rem' : '1.5rem'};
                            text-align: center;
                            color: #ffffff;
                            margin-bottom: 24px;
                            z-index: 1;
                        }

                        &__subTitle {
                            width: 100%;
                            font-size: ${isDesktopView || isTabletView ? '25px' : '18px'};
                            color: #ffffff;
                            font-weight: normal;
                            line-height: ${isDesktopView ? '1.5rem' : '1.25rem'};
                            text-align: center;
                            margin-top: ${isDesktopView || isTabletView ? '5px' : '3px'};
                            padding: 0;
                            margin-bottom: 24px;
                            z-index: 1;
                        }

                        &__subWrapper {
                            flex-wrap: wrap;
                            height: auto;
                            width: ${isDesktopView ? 'auto' : isTabletView ? '768px' : '100%'};
                            display: flex;
                            flex-direction: ${isDesktopView || isTabletView ? 'row' : 'column'};
                            justify-content: ${isDesktopXLView
                                ? 'space-between'
                                : isDesktopView || isTabletView
                                ? 'center'
                                : 'center'};
                            align-items: ${isDesktopView || isTabletView ? 'stretch' : 'center'};
                            gap: ${isDesktopView ? '24px' : isTabletView ? '16px' : '16px'};
                        }

                        &__contactUs {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 1.25rem;
                            color: #ffffff;
                            z-index: 1;
                            margin-top: ${isDesktopView
                                ? '24.5px'
                                : isTabletView
                                ? '20px'
                                : '16px'};
                        }
                    }
                `}
            </style>
            <style jsx global>
                {`
                    .NewAffordablePlansEnterprise__commonLink {
                        color: #2298ca !important;
                        text-underline-offset: 1px !important;
                        text-decoration-line: underline !important;
                        -webkit-text-decoration-line: underline !important;
                        text-decoration-thickness: 1px !important;
                        text-underline-position: under !important;

                        &:hover {
                            color: #e0e0e0 !important;
                        }
                    }
                `}
            </style>
        </section>
    );
}

NewAffordablePlansEnterprise.propTypes = {
    WindowDimension: PropTypes.objectOf(PropTypes.shape).isRequired,
};

export default NewAffordablePlansEnterprise;
