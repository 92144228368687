import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';

// Header Components
const UIMainHeader = dynamic(() => import('./UIMainHeader'));
const UISimpleHeader = dynamic(() => import('./UISimpleHeader'));
const SimpleSearchbar = dynamic(() => import('components/Shared/SimpleSearchBar'));

function PageHeader({ headerType, theme, locale, User, lang, onChangeLang, onSupportClick }) {
    const router = useRouter();
    const paths = { en: router.asPath };

    if (headerType === 'main') {
        return (
            <div>
                <UIMainHeader
                    theme={theme}
                    localeData={locale}
                    User={User}
                    paths={paths}
                    onChangeLang={onChangeLang}
                    onSupportClick={onSupportClick}
                />
                <SimpleSearchbar lang={lang} />
            </div>
        );
    }

    return (
        <UISimpleHeader
            theme={theme}
            localeData={locale}
            User={User}
            paths={paths}
            onChangeLang={onChangeLang}
            onSupportClick={onSupportClick}
        />
    );
}

PageHeader.propTypes = {
    headerType: PropTypes.string,
    theme: PropTypes.string,
    locale: PropTypes.objectOf(PropTypes.shape).isRequired,
    User: PropTypes.objectOf(PropTypes.shape).isRequired,
    lang: PropTypes.string,
    onChangeLang: PropTypes.func,
    onSupportClick: PropTypes.func,
};

PageHeader.defaultProps = {
    headerType: 'simple',
    theme: 'dark',
    lang: 'en',
    onChangeLang: () => null,
    onSupportClick: () => null,
};

export default PageHeader;
