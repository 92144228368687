import axios from 'axios';
import { buildConfig } from 'helpers/axios';

const getUserActivePlusPlan = async (ctx = undefined, userId) => {
    try {
        const config = buildConfig(ctx);
        const url = `/apicore-payments/plus/hasActivePlan/${userId}`;

        const response = await axios.get(url, config);
        // console.log('getUserActivePlusPlan response:', response);
        return response?.data?.data || {};
    } catch (error) {
        // console.log('getUserActivePlusPlan error:', error);
        return { active_plan: false, duration: 0 };
    }
};

export default getUserActivePlusPlan;
