import dynamic from 'next/dynamic';
import intl from 'react-intl-universal';

// Sub Component
import PlanDetailsItem from 'components/Products/NewAffordablePlans/Shared/PlanDetailsItem';
import { svg } from '123rf-ui-core/assets';

// Dynamic Component
const Tooltips = dynamic(() => import('123rf-ui-core/components').then((mod) => mod.Tooltips));

const PlusBusinessPlanDetails = () => {
    const AccessToAISuite = (
        <div className="PlusBusinessPlanDetails__container--tooltip">
            <span>{intl.get('PRODUCTS.PlusAccessToAISuite')}</span>
            <img
                id="ai-suite-plus-business-tooltip"
                className="PlusBusinessPlanDetails__container--tooltipImage"
                alt="information"
                src={svg.information}
                width={16}
                height={16}
            />

            <Tooltips
                innerClassName="PlusBusinessPlanDetails__container--innerTooltip"
                target="ai-suite-plus-business-tooltip"
                position="bottom"
            >
                <div className="PlusBusinessPlanDetails__tooltip--container">
                    <span className="PlusBusinessPlanDetails__tooltip--header">
                        {intl.get('PRODUCTS.WhatsIncluded')}
                    </span>
                    <ul className="PlusBusinessPlanDetails__tooltip--list">
                        <li>{intl.get('AI_IMAGE_GENERATOR')}</li>
                        <li>{intl.get('AI_WRITER')}</li>
                        <li>{intl.get('AI_REMOVAL_BG')}</li>
                        <li>{intl.get('AI_BG_REMIX')}</li>
                        <li>{intl.get('AI_IMAGE_EXTENDER')}</li>
                        <li>{intl.get('AI_IMAGE_UPSCALER')}</li>
                        <li>{intl.get('AI_BACKGROUND_BLUR')}</li>
                    </ul>
                </div>
            </Tooltips>
        </div>
    );

    return (
        <ul className="PlusBusinessPlanDetails__wrapper">
            <PlanDetailsItem item={AccessToAISuite} />
            <PlanDetailsItem item={intl.get('PRODUCTS.PlusUnlimitedDownloads')} />
            <PlanDetailsItem item={intl.getHTML('PRODUCTS.StandardLicense')} type='link'/>
            <PlanDetailsItem item={intl.getHTML('PRODUCTS.MultipleUsersGetPlusBusinessTeam')} type='link'/>

            <style jsx>
                {`
                    .PlusBusinessPlanDetails {
                        &__wrapper {
                            width: 100%;
                            height: auto;
                            display: flex;
                            flex-direction: column;
                            gap: 4px;
                            margin-top: 16px;
                        }
                    }
                `}
            </style>

            <style jsx global>
                {`
                    .PlusBusinessPlanDetails {
                        &__container {
                            &--tooltip {
                                display: flex;
                                gap: 8px;
                                align-items: center;
                            }

                            &--innerTooltip {
                                min-width: 200px;
                                max-width: 300px !important;

                                padding: 8px !important;
                                border-radius: 8px !important;
                            }

                            &--tooltipImage {
                                cursor: pointer;
                            }
                        }

                        &__tooltip {
                            &--container {
                                display: flex;
                                flex-direction: column;
                                gap: 8px;
                            }

                            &--header {
                                color: #212121;
                                font-size: 12px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 1.25rem;
                            }

                            &--list {
                                margin-bottom: 0;

                                & > li {
                                    color: #212121;
                                    font-size: 12px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 1.25rem;
                                }
                            }
                        }
                    }
                `}
            </style>
        </ul>
    );
};

export default PlusBusinessPlanDetails;
