import axios from 'axios';
import { buildConfig } from 'helpers/axios';

const getAllProducts = async ({
    ctx,
    countryCode,
    subsPlanNames,
    aioPlanNames,
    creditsList,
    psubPlanNames,
    slim,
}) => {
    try {
        const config = buildConfig(ctx);
        const params = `?country_code=${countryCode}&sub_plan_names=${subsPlanNames}&aio_plan_names=${aioPlanNames}&no_of_credits=${creditsList}&psub_plan_names=${psubPlanNames}&slim=${slim}`;

        const url = `/apicore-payments/products${params}`;
        const reqConfig = {
            headers: {
                'Content-Type': 'application/vnd.api+json',
            },
        };

        // const response = await axios.get(url, reqConfig);
        const response = await axios.get(url, config, reqConfig);
        // console.log('getAllProducts response:', response);
        return response?.data?.data?.attributes || null;
    } catch (error) {
        // console.log('getAllProducts error:', error);
        return null;
    }
};
export default getAllProducts;
