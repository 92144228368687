const redirectTo = (res, location) => {
    if (res?.writeHead) {
        res.writeHead(303, {
            Location: location,
        });
        res.end();
        res.finished = true;
    }
};

export default redirectTo;