/* eslint-disable jsx-a11y/anchor-is-valid */

import intl from 'react-intl-universal';
import PropTypes from 'prop-types';

// Sub Component
import PlanDetailsItem from 'components/Products/NewAffordablePlans/Shared/PlanDetailsItem';

const CreditPackDetails = ({ refCreditPacksCanBuy }) => {
    const WhatCanIGetWithCredits = () => (
        <a
            className="TextLink TextLink--primary CreditPackDetails__link"
            role="button"
            tabIndex="0"
            onClick={() =>
                refCreditPacksCanBuy.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                })
            }
            onKeyDown={(e) =>
                e.key === 'Enter' &&
                refCreditPacksCanBuy.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                })
            }
        >
            {intl.getHTML('PRODUCTS.CreditWhatCanIGet')}
        </a>
    );
    return (
        <ul className="CreditPackDetails__wrapper">
            <PlanDetailsItem item={intl.getHTML('PRODUCTS.CreditDownloadStocks')} />
            <PlanDetailsItem item={intl.getHTML('PRODUCTS.CreditPremiumCollection')} />
            <PlanDetailsItem item={intl.getHTML('PRODUCTS.CreditDownloadWhatAndWhen')} />
            <PlanDetailsItem item={intl.getHTML('PRODUCTS.CreditStandardExtendedLicenses')} type="link" />
            <PlanDetailsItem item={intl.getHTML('PRODUCTS.CreditMixMatch')} />
            <PlanDetailsItem item={WhatCanIGetWithCredits()} type="link" />

            <style jsx>
                {`
                    .CreditPackDetails {
                        &__wrapper {
                            width: 100%;
                            height: auto;
                            display: flex;
                            flex-direction: column;
                            gap: 4px;
                            margin-top: 16px;
                        }
                    }
                `}
            </style>
        </ul>
    );
};

CreditPackDetails.propTypes = {
    refCreditPacksCanBuy: PropTypes.objectOf(PropTypes.shape).isRequired,
};

export default CreditPackDetails;
