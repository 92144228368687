import intl from 'react-intl-universal';
import PropTypes from 'prop-types';

// Pre-defined Components
import { DEFAULT_CREDITS, PLAN_TYPES } from 'config/products';

// Sub Components
import CreditsTextInput from 'components/Products/NewAffordablePlans/CreditPack/CreditsTextInput';

const PlanParticulars = (props) => {
    const { cardType, credits, quota, isYearlyPlan, data, showCustomCredits, isLangKR } = props;

    const isPLUSFreeTrialPlan = data?.plan === 'fplus7';
    const isAnnual = data?.duration === 12;

    const displayQuotaCreditsOrTextInput = () => {
        if (cardType === PLAN_TYPES.SUBSCRIPTION) return quota;
        if (cardType === PLAN_TYPES.PLUS) return '';
        if (showCustomCredits && credits === DEFAULT_CREDITS.defaultCustom)
            return <CreditsTextInput {...props} />;
        return credits;
    };

    const displayPlanOrCreditsLabel = () => (
        <span className="PlanParticulars__label PlanParticulars__label--longText">
            {cardType === PLAN_TYPES.PLUS && isPLUSFreeTrialPlan ? (
                intl.get('PRODUCTS.SevenDaysFreeTrial')
            ) : (
                <>
                    {cardType === PLAN_TYPES.SUBSCRIPTION && isYearlyPlan && isLangKR && '매월 '}
                    {displayQuotaCreditsOrTextInput()}
                    {cardType !== PLAN_TYPES.PLUS && <>&nbsp;</>}
                    {intl.get(
                        cardType === PLAN_TYPES.SUBSCRIPTION
                            ? 'PRODUCTS.ChoosePlan_SubscriptionPlanImages'
                            : cardType === PLAN_TYPES.PLUS
                            ? isAnnual
                                ? 'PRODUCTS.YearlySubscription'
                                : 'PRODUCTS.MonthlySubscription'
                            : 'SHARED.Credits',
                    )}
                </>
            )}
        </span>
    );

    if (!showCustomCredits && credits === DEFAULT_CREDITS.defaultCustom) {
        return (
            <div className="PlanParticulars__wrapper">
                <span className="PlanParticulars__label PlanParticulars__label--textNormal">
                    {intl.get('PRODUCTS.ChoosePlan_OnDemandCreditsCustomCredits')}
                </span>
            </div>
        );
    }

    return (
        <div>
            <div className="PlanParticulars__wrapper">{displayPlanOrCreditsLabel()}</div>

            <style jsx global>
                {`
                    .PlanParticulars {
                        &__wrapper {
                            width: 100%;
                            height: auto;
                        }

                        &__label {
                            display: flex;
                            align-items: center;
                            font-weight: 600;
                            font-size: 12px;
                            line-height: 1.125rem;
                            color: #212121;

                            &--textNormal {
                                text-transform: unset;
                            }

                            &--longText {
                                min-width: 60px;
                            }
                        }
                    }
                `}
            </style>
        </div>
    );
};

PlanParticulars.propTypes = {
    cardType: PropTypes.string.isRequired,
    credits: PropTypes.number.isRequired,
    quota: PropTypes.number.isRequired,
    isYearlyPlan: PropTypes.bool.isRequired,
    data: PropTypes.objectOf(PropTypes.shape).isRequired,
    selectedPlan: PropTypes.string,
    selectedCredits: PropTypes.number,
    showCustomCredits: PropTypes.bool,
    isCustom: PropTypes.bool,
    customCreditsNote: PropTypes.string,
    customCreditsDetails: PropTypes.objectOf(PropTypes.shape),
    isLangKR: PropTypes.bool.isRequired,
    handleOptionSelection: PropTypes.func.isRequired,
};

PlanParticulars.defaultProps = {
    selectedPlan: '',
    selectedCredits: 0,
    showCustomCredits: false,
    isCustom: false,
    customCreditsNote: '',
    customCreditsDetails: {},
};

export default PlanParticulars;
