import axios from 'axios';
import { buildConfig } from 'helpers/axios';

const getFreePlusPromoUse = async (ctx = undefined) => {
    try {
        const config = buildConfig(ctx);
        const url = `/apicore-payments/promo/freeplus-promocode`;

        const response = await axios.get(url, config);
        return response.data;
    } catch (error) {
        return { promo_use: false, use_count: 0 };
    }
};
export default getFreePlusPromoUse;
