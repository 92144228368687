import axios from 'axios';
import { buildConfig } from 'helpers/axios';

const getCustomCredits = async (ctx = undefined, country, credits, signal) => {
    try {
        const config = buildConfig(ctx);
        const params = `?country=${country}&credits=${credits}`;
        const url = `/apicore-payments/products/credits${params}`;
        const reqConfig = {
            headers: {
                'Content-Type': 'application/vnd.api+json',
            },
            signal,
        };

        const response = await axios.get(url, config, reqConfig);
        // console.log('getCustomCredits response:', response);
        return response.data.data.attributes;
    } catch (error) {
        // console.log('getCustomCredits error:', error);
        return null;
    }
};

export default getCustomCredits;
