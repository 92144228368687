import { CHECKOUT_SUB_PATHS, LOGIN_PATH, PRODUCTS_PATH } from 'config/constants';
import { GLOBAL_LANG } from 'config/cookies';
import { SUPPORTED_HREF_LANGS as supportedLangs } from 'config/global';
import checkIsServer from './checkIsServer';
import getSiteLanguage from './getSiteLanguage';
import redirectTo from './redirectTo';

const redirectToInitLangSite = (props) => {
    const { ctx, cookies, hostName } = props;
    const lang = cookies[GLOBAL_LANG];

    if (
        checkIsServer(ctx) &&
        !CHECKOUT_SUB_PATHS.includes(ctx?.pathname) &&
        getSiteLanguage(hostName) === 'en' &&
        lang &&
        lang !== 'en'
    ) {
        redirectTo(ctx.res, `https://${lang}.123rf.com${ctx.asPath}`);
        return true;
    }

    return false;
};

export default redirectToInitLangSite;