import PropTypes from 'prop-types';
import intl from 'react-intl-universal';
import { PLAN_TYPES } from 'config/products';

const HeaderGroup = (props) => {
    const { cardType } = props;

    return (
        <div className="HeaderGroup__group">
            <h2 className="HeaderGroup__title">
                {cardType === PLAN_TYPES.SUBSCRIPTION
                    ? intl.get('PRODUCTS.PremiumSubscription')
                    : cardType === PLAN_TYPES.CREDIT
                    ? intl.get('PRODUCTS.Credits')
                    : cardType === PLAN_TYPES.PLUS
                    ? intl.get('PRODUCTS.Plus')
                    : cardType === PLAN_TYPES.PLUS_BUSINESS
                    ? intl.get('PRODUCTS.PlusBusiness')
                    : intl.get('PRODUCTS.PremiumEnterprise')}
            </h2>

            <span className="HeaderGroup__subtitle">
                {cardType === PLAN_TYPES.SUBSCRIPTION
                    ? intl.get('PRODUCTS.FullCollectionForBusiness')
                    : cardType === PLAN_TYPES.CREDIT
                    ? intl.get('PRODUCTS.AllMediaTypesFlexible')
                    : cardType === PLAN_TYPES.PLUS
                    ? intl.get('PRODUCTS.IdealForDigitalCreators')
                    : intl.get('PRODUCTS.ForSmallMediumBusiness')}
            </span>

            <style jsx>
                {`
                    .HeaderGroup {
                        &__group {
                            flex-direction: column;
                            width: 100%;
                            display: flex;
                            align-items: center;
                            flex-wrap: wrap;
                            gap: 4px;
                            justify-content: center;
                        }

                        &__title {
                            font-size: 16px;
                            font-weight: 600;
                            letter-spacing: 0;
                            white-space: nowrap;
                            color: #212121;
                            line-height: 1.5rem;
                        }

                        &__subtitle {
                            font-size: 12px;
                            font-weight: 400;
                            color: #212121;
                            text-align: center;
                        }
                    }
                `}
            </style>
        </div>
    );
};

HeaderGroup.propTypes = {
    cardType: PropTypes.string.isRequired,
};

export default HeaderGroup;
