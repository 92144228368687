import { useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';
import { useRouter } from 'next/router';

import { ENTERPRISE_PATH, PRODUCTS_PATH } from 'config/constants';

const PlanType = (props) => {
    const { CommonDetails, WindowDimension } = props;
    const { lang, pageType } = CommonDetails;
    const { isMobileView, width, height } = WindowDimension;
    const Router = useRouter();

    const [sliderLeft, setSliderLeft] = useState(6);

    const stockRef = useRef();
    const aiRef = useRef();

    // Countries with long translation that requires the switch width to be adjusted
    const resizeSwitchLangs = ['PL', 'JP'];
    const resizeSwitch = resizeSwitchLangs.includes(lang.toUpperCase());

    const toggleSwitch = () => {
        Router.push(pageType === 'products' ? `${ENTERPRISE_PATH}/` : `${PRODUCTS_PATH}/`);
    };

    useEffect(() => {
        setSliderLeft(
            pageType === 'products' ? stockRef.current.offsetLeft : aiRef.current.offsetLeft,
        );
    }, [width, height, stockRef, aiRef]);

    return (
        <div className="PlanType__container">
            <button
                ref={stockRef}
                type="button"
                onClick={() => toggleSwitch()}
                className={cx('PlanType__container__planType', {
                    'PlanType__container__planType--primary': pageType === 'products',
                    'PlanType__container__planType--secondary': pageType !== 'products',
                })}
            >
                <span>{intl.get('PRODUCTS.Individual')}</span>
            </button>
            <button
                ref={aiRef}
                type="button"
                onClick={() => toggleSwitch()}
                className={cx('PlanType__container__planType', {
                    'PlanType__container__planType--primary': pageType === 'enterprise',
                    'PlanType__container__planType--secondary': pageType !== 'enterprise',
                })}
            >
                <span>{intl.get('PRODUCTS.TeamsAndEnterprise')}</span>
            </button>
            <div className="PlanType__container__planType PlanType__container__planType--slider" />

            <style jsx>
                {`
                    .PlanType {
                        &__container {
                            display: flex;
                            flex-direction: row;
                            background-color: #212121;
                            box-shadow: 0px 0px 2px 0px rgba(33, 33, 33, 0.25) inset;
                            border-radius: 4px;
                            min-height: 40px;
                            min-width: ${isMobileView ? 'auto' : '400px'};
                            width: ${isMobileView ? '100%' : 'auto'};
                            max-width: ${isMobileView ? '400px' : 'auto'};
                            margin-bottom: 24px;
                            align-items: center;
                            padding: 2px;
                            z-index: 1;
                            position: relative;

                            &__planType {
                                display: flex;
                                height: 100%;
                                padding: ${resizeSwitch ? '8px 32px' : '8px 16px'};
                                justify-content: center;
                                align-items: center;
                                font-size: 14px;
                                line-height: 20px;
                                border-radius: 4px;
                                margin: 2px;
                                cursor: pointer;
                                -webkit-user-select: none;
                                user-select: none;
                                flex: 1;
                                white-space: ${isMobileView ? 'initial' : 'nowrap'};
                                overflow: hidden;

                                border: none;
                                background: none;
                                -webkit-appearance: none;
                                -moz-appearance: none;

                                font-size: ${isMobileView ? '12px' : '16px'};
                                font-weight: 600;
                                line-height: ${isMobileView ? '1.125rem' : '1.5rem'};

                                &--primary {
                                    color: #212121;
                                }

                                &--secondary {
                                    color: #e0e0e0 !important;
                                }

                                &--slider {
                                    position: absolute;
                                    width: calc(50% - 6px);
                                    height: ${isMobileView
                                        ? 'calc(100% - 8px)'
                                        : 'calc(100% - 12px)'};
                                    min-height: ${isMobileView ? 'auto' : '40px'};
                                    background-color: #ffffff;
                                    left: ${sliderLeft - 2}px;
                                    transition: left 0.2s ease;
                                    z-index: -1;
                                }
                            }
                        }
                    }
                `}
            </style>
        </div>
    );
};

PlanType.propTypes = {
    CommonDetails: PropTypes.objectOf(PropTypes.shape).isRequired,
    WindowDimension: PropTypes.objectOf(PropTypes.shape).isRequired,
};

export default PlanType;
