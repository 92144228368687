const toSentenceCase = (text) => {
    if (!text || typeof text !== 'string') return '';

    return text
        .split('. ')
        .map((sentence) => sentence.charAt(0).toUpperCase() + sentence.slice(1).toLowerCase())
        .join('. ');
};

export default toSentenceCase;
