/* eslint-disable import/prefer-default-export */

const productsAssetsPath = `${process.env.ASSETS_CDN_PUBLIC}/payments/assets/images`;
const productsAssetsSVGPath = `${process.env.ASSETS_CDN_PUBLIC}/ui-components/assets/svg`;
// Enable to use below path in development phase
// const staticAssetsPath = `${process.env.ASSETS_CDN}/static/images`;

export const PRODUCTS_IMAGES = {
    // bestValueIcon: `${productsAssetsPath}/icon_bestvalue.png`,
    // businessIcon: `${productsAssetsPath}/businessicon.png`,
    // businessIconWebp: `${productsAssetsPath}/businessicon.webp`,
    closeIcon: `${productsAssetsPath}/close_icon.png`,
    closeIconWebp: `${productsAssetsPath}/close_icon.webp`,
    // closeIconWhite: `${productsAssetsPath}/close-icon-white.png`,
    // closeIconWhiteWebp: `${productsAssetsPath}/close-icon-white.webp`,
    // contactUs: `${productsAssetsPath}/contact-us.png`,
    // contactUsWebp: `${productsAssetsPath}/contact-us.webp`,
    // creativeIcon: `${productsAssetsPath}/creative-icon.png`,
    // creativeIconWebp: `${productsAssetsPath}/creative-icon.webp`,
    // credits: `${productsAssetsPath}/credits.png`,
    // creditsWebp: `${productsAssetsPath}/credits.webp`,
    // designsAIAssets: `${productsAssetsPath}/designsai-assets.png`,
    // designsAILogo: `${productsAssetsPath}/designsai-logo.png`,
    // downIcon: `${productsAssetsPath}/down_icon.svg`,
    // downloadPack: `${productsAssetsPath}/download-pack.svg`,
    // downloadPackWebp: `${productsAssetsPath}/download-pack.webp`,
    // enterpriseSolution: `${productsAssetsPath}/enterprise-solution.jpg`,
    // enterpriseSolutionWebp: `${productsAssetsPath}/enterprise-solution.webp`,
    faqImage1: `${productsAssetsPath}/faq-img1.jpg`,
    faqImage2: `${productsAssetsPath}/faq-img2.jpg`,
    faqImage1Webp: `${productsAssetsPath}/faq-img1.webp`,
    faqImage2Webp: `${productsAssetsPath}/faq-img2.webp`,
    // guaranteeIcon: `${productsAssetsPath}/guaranteeicon.png`,
    // guaranteeIconWebp: `${productsAssetsPath}/guaranteeicon.webp`,
    // infiniteIcon: `${productsAssetsPath}/infiniteicon.png`,
    // infiniteIconWebp: `${productsAssetsPath}/infiniteicon.webp`,
    // liveChatHead: `${productsAssetsPath}/live-chat-head.jpg`,
    // liveChatHeadWebp: `${productsAssetsPath}/live-chat-head.webp`,
    // liveChatCloseIcon: `${productsAssetsPath}/livechat-closebtn.png`,
    // liveChatCloseIconWebp: `${productsAssetsPath}/livechat-closebtn.webp`,
    // mostPopularIcon: `${productsAssetsPath}/icon_mostpopular.png`,
    // mostPopularIconWebp: `${productsAssetsPath}/icon_mostpopular.webp`,
    // pixlrAssets: `${productsAssetsPath}/pixlr-assets.png`,
    // pixlrDesignsAILogo: `${productsAssetsPath}/pixlr-designsai-logo.png`,
    // pixlrLogo: `${productsAssetsPath}/pixlr-logo.png`,
    // plusIcon: `${productsAssetsPath}/plus.svg`,
    // plusIconWebp: `${productsAssetsPath}/plus.webp`,
    // pricingPlanBG: `${productsAssetsPath}/66491865_l.jpg`,
    // pricingPlanXXLBG: `${productsAssetsPath}/69222894_l.jpg`,
    // promoBannerSME: `${productsAssetsPath}/sme-desktop_MY.jpg`,
    // promoBannerSMEMobile: `${productsAssetsPath}/sme-mobile_MY.jpg`,
    // promoBannerLess15: `${productsAssetsPath}/less15-pricing-en.png`,
    // promoBannerLess15Mobile: `${productsAssetsPath}/less15-mobile-en.jpg`,
    // reviewerIcon1: `${productsAssetsPath}/icon_reviewer1.png`,
    // reviewerIcon1Webp: `${productsAssetsPath}/icon_reviewer1.webp`,
    // reviewerIcon2: `${productsAssetsPath}/icon_reviewer2.png`,
    // reviewerIcon2Webp: `${productsAssetsPath}/icon_reviewer2.webp`,
    // reviewerIcon3: `${productsAssetsPath}/icon_reviewer3.png`,
    // reviewerIcon3Webp: `${productsAssetsPath}/icon_reviewer3.webp`,
    // subscriptionPlan: `${productsAssetsPath}/subscriptions-plan.svg`,
    // subscriptionPlanWebp: `${productsAssetsPath}/subscriptions_plan.webp`,
    // pricingBG320: `${productsAssetsPath}/pricing-bg-320.png`,
    // pricingBG320webp: `${productsAssetsPath}/pricing-bg-320.webp`,
    // pricingBG767: `${productsAssetsPath}/pricing-bg-767.png`,
    // pricingBG767webp: `${productsAssetsPath}/pricing-bg-767.webp`,
    // pricingBG768: `${productsAssetsPath}/pricing-bg-768.png`,
    // pricingBG768webp: `${productsAssetsPath}/pricing-bg-768.webp`,
    // pricingBG1199: `${productsAssetsPath}/pricing-bg-1199.png`,
    // pricingBG1199webp: `${productsAssetsPath}/pricing-bg-1199.webp`,
    // pricingBG1440: `${productsAssetsPath}/pricing-bg-1440.png`,
    // pricingBG1440webp: `${productsAssetsPath}/pricing-bg-1440.webp`,
    // pricingBG2000: `${productsAssetsPath}/pricing-bg-2000.png`,
    // pricingBG2000webp: `${productsAssetsPath}/pricing-bg-2000.webp`,
    // affordablePlanBG577: `${productsAssetsPath}/pricing-bg-yellow-577.jpg`,
    // affordablePlanBG768: `${productsAssetsPath}/pricing-bg-yellow-768.jpg`,
    // affordablePlanBG900: `${productsAssetsPath}/pricing-bg-yellow-1080.jpg`,
    // affordablePlanBG1200: `${productsAssetsPath}/pricing-bg-yellow-1297.jpg`,
    // affordablePlanBG1400: `${productsAssetsPath}/pricing-bg-yellow-1440.jpg`,
    // affordablePlanBG1700: `${productsAssetsPath}/pricing-bg-yellow-1729.jpg`,
    // affordablePlanBG2000: `${productsAssetsPath}/pricing-bg-yellow-2160.jpg`,
    // affordablePlanBG577Webp: `${productsAssetsPath}/pricing-bg-yellow-577.webp`,
    // affordablePlanBG768Webp: `${productsAssetsPath}/pricing-bg-yellow-768.webp`,
    // affordablePlanBG900Webp: `${productsAssetsPath}/pricing-bg-yellow-1080.webp`,
    // affordablePlanBG1200Webp: `${productsAssetsPath}/pricing-bg-yellow-1297.webp`,
    // affordablePlanBG1400Webp: `${productsAssetsPath}/pricing-bg-yellow-1440.webp`,
    // affordablePlanBG1700Webp: `${productsAssetsPath}/pricing-bg-yellow-1729.webp`,
    // affordablePlanBG2000Webp: `${productsAssetsPath}/pricing-bg-yellow-2160.webp`,
    // customizedPlan1: `${productsAssetsPath}/customized-img1.jpg`,
    // customizedPlan2: `${productsAssetsPath}/customized-img2.jpg`,
    // customizedPlan3: `${productsAssetsPath}/customized-img3.jpg`,
    // customizedPlan4: `${productsAssetsPath}/customized-img4.jpg`,
    // customizedPlan1Webp: `${productsAssetsPath}/customized-img1.webp`,
    // customizedPlan2Webp: `${productsAssetsPath}/customized-img2.webp`,
    // customizedPlan3Webp: `${productsAssetsPath}/customized-img3.webp`,
    // customizedPlan4Webp: `${productsAssetsPath}/customized-img4.webp`,
    // makesDifferent1: `${productsAssetsPath}/different-img1.svg`,
    // makesDifferent2: `${productsAssetsPath}/different-img2.svg`,
    // makesDifferent3: `${productsAssetsPath}/different-img3.svg`,
    // makesDifferent4: `${productsAssetsPath}/different-img4.svg`,
    // pricingTick: `${productsAssetsPath}/pricing-tick.png`,
    // pricingTickWebp: `${productsAssetsPath}/pricing-tick.webp`,
    // pricingTickX2: `${productsAssetsPath}/pricing-tick-x2.png`,
    // pricingTickX2Webp: `${productsAssetsPath}/pricing-tick-x2.webp`,
    // whatADealDesktop: `${productsAssetsPath}/what-a-deal-desktop.png`,
    // whatADealMobile: `${productsAssetsPath}/what-a-deal-mobile.png`,

    accentureLogo: `${productsAssetsPath}/accenture-logo.png`,
    accentureLogoWebp: `${productsAssetsPath}/accenture-logo.webp`,
    unitedLogo: `${productsAssetsPath}/united-logo.png`,
    unitedLogoWebp: `${productsAssetsPath}/united-logo.webp`,
    ogilvyLogo: `${productsAssetsPath}/ogilvy-logo.png`,
    ogilvyLogoWebp: `${productsAssetsPath}/ogilvy-logo.webp`,
    unileverLogo: `${productsAssetsPath}/unilever-logo.png`,
    unileverLogoWebp: `${productsAssetsPath}/unilever-logo.webp`,
    merckLogo: `${productsAssetsPath}/merck-logo.png`,
    merckLogoWebp: `${productsAssetsPath}/merck-logo.webp`,
    bayerLogo: `${productsAssetsPath}/bayer-logo.png`,
    bayerLogoWebp: `${productsAssetsPath}/bayer-logo.webp`,
    marriottLogo: `${productsAssetsPath}/marriott-logo.png`,
    marriottLogoWebp: `${productsAssetsPath}/marriott-logo.webp`,
    chevronLogo: `${productsAssetsPath}/chevron-logo.png`,
    chevronLogoWebp: `${productsAssetsPath}/chevron-logo.webp`,
    masterCardLogo: `${productsAssetsPath}/master-card-logo.png`,
    masterCardLogoWebp: `${productsAssetsPath}/master-card-logo.webp`,
    lorealLogo: `${productsAssetsPath}/loreal-logo.png`,
    lorealLogoWebp: `${productsAssetsPath}/loreal-logo.webp`,
    accorLogo: `${productsAssetsPath}/accor-logo.png`,
    accorLogoWebp: `${productsAssetsPath}/accor-logo.webp`,
    googleLogo: `${productsAssetsPath}/google-logo.png`,
    googleLogoWebp: `${productsAssetsPath}/google-logo.webp`,
    grabLogo: `${productsAssetsPath}/grab-logo.png`,
    grabLogoWebp: `${productsAssetsPath}/grab-logo.webp`,
    sourceNextLogo: `${productsAssetsPath}/source-next-logo.png`,
    sourceNextLogoWebp: `${productsAssetsPath}/source-next-logo.webp`,

    // accentureGreyscaledLogo: `${productsAssetsPath}/accenture-greyscaled.png`,
    // accentureGreyscaledLogoWebp: `${productsAssetsPath}/accenture-greyscaled.webp`,
    // unitedGreyscaledLogo: `${productsAssetsPath}/united-greyscaled.png`,
    // unitedGreyscaledLogoWebp: `${productsAssetsPath}/united-greyscaled.webp`,
    // ogilvyGreyscaledLogo: `${productsAssetsPath}/ogilvy-greyscaled.png`,
    // ogilvyGreyscaledLogoWebp: `${productsAssetsPath}/ogilvy-greyscaled.webp`,
    // unileverGreyscaledLogo: `${productsAssetsPath}/unilever-greyscaled.png`,
    // unileverGreyscaledLogoWebp: `${productsAssetsPath}/unilever-greyscaled.webp`,
    // merckGreyscaledLogo: `${productsAssetsPath}/merck-greyscaled.png`,
    // merckGreyscaledLogoWebp: `${productsAssetsPath}/merck-greyscaled.webp`,
    // bayerGreyscaledLogo: `${productsAssetsPath}/bayer-greyscaled.png`,
    // bayerGreyscaledLogoWebp: `${productsAssetsPath}/bayer-greyscaled.webp`,
    // marriottGreyscaledLogo: `${productsAssetsPath}/marriot-greyscaled.png`,
    // marriottGreyscaledLogoWebp: `${productsAssetsPath}/marriot-greyscaled.webp`,
    // chevronGreyscaledLogo: `${productsAssetsPath}/chevron-greyscaled.png`,
    // chevronGreyscaledLogoWebp: `${productsAssetsPath}/chevron-greyscaled.webp`,
    // masterCardGreyscaledLogo: `${productsAssetsPath}/mastercard-greyscaled.png`,
    // masterCardGreyscaledLogoWebp: `${productsAssetsPath}/mastercard-greyscaled.webp`,
    // lorealGreyscaledLogo: `${productsAssetsPath}/loreal-greyscaled.png`,
    // lorealGreyscaledLogoWebp: `${productsAssetsPath}/loreal-greyscaled.webp`,
    // accorGreyscaledLogo: `${productsAssetsPath}/accor-greyscaled.png`,
    // accorGreyscaledLogoWebp: `${productsAssetsPath}/accor-greyscaled.webp`,
    // googleGreyscaledLogo: `${productsAssetsPath}/google-greyscaled.png`,
    // googleGreyscaledLogoWebp: `${productsAssetsPath}/google-greyscaled.webp`,
    // grabGreyscaledLogo: `${productsAssetsPath}/grab-greyscaled.png`,
    // grabGreyscaledLogoWebp: `${productsAssetsPath}/grab-greyscaled.webp`,
    // sourceNextGreyscaledLogo: `${productsAssetsPath}/sourcenext-greyscaled.png`,
    // sourceNextGreyscaledLogoWebp: `${productsAssetsPath}/sourcenext-greyscaled.webp`,

    enterpriseMoney: `${productsAssetsPath}/enterprise-money.svg`,
    enterpriseWrench: `${productsAssetsPath}/enterprise-wrench.svg`,
    enterpriseTeam: `${productsAssetsPath}/enterprise-team.svg`,

    customizedSolutionContactUs: `${productsAssetsPath}/customized-solution-contact-us.png`,
    customizedSolutionContactUsWebp: `${productsAssetsPath}/customized-solution-contact-us.webp`,

    // moneyBackGuaranteeImage: `${productsAssetsPath}/money-back-guarantee.svg`,
    // legalGuaranteeImage: `${productsAssetsPath}/legal-guarantee.svg`,
    // commercialPrintImage: `${productsAssetsPath}/commercial-print.svg`,
    // newContentDailyImage: `${productsAssetsPath}/new-content-daily.svg`,

    chatWithUsWebp: `${productsAssetsPath}/chat-with-us.webp`,
    chatWithUs: `${productsAssetsPath}/chat-with-us.png`,
    chevronDownYellowWebp: `${productsAssetsPath}/chevron-down-yellow.webp`,
    chevronDownYellow: `${productsAssetsPath}/chevron-down-yellow.png`,
    avatarWebp: `${productsAssetsPath}/avatar.webp`,
    avatar: `${productsAssetsPath}/avatar.png`,
    whatsapp: `${productsAssetsPath}/whatsapp.svg`,
    headset: `${productsAssetsPath}/headset.svg`,

    // newAffordablePlanBG2000Webp: `${productsAssetsPath}/affordable-plan-bg-2000v2.webp`,
    // newAffordablePlanBG2000Png: `${productsAssetsPath}/affordable-plan-bg-2000v2.png`,
    // newAffordablePlanBG1700Webp: `${productsAssetsPath}/affordable-plan-bg-1700v2.webp`,
    // newAffordablePlanBG1700Png: `${productsAssetsPath}/affordable-plan-bg-1700v2.png`,
    // newAffordablePlanBG1440Webp: `${productsAssetsPath}/affordable-plan-bg-1440v2.webp`,
    // newAffordablePlanBG1440Png: `${productsAssetsPath}/affordable-plan-bg-1440v2.png`,
    // newAffordablePlanBG1200Webp: `${productsAssetsPath}/affordable-plan-bg-1200v2.webp`,
    // newAffordablePlanBG1200Png: `${productsAssetsPath}/affordable-plan-bg-1200v2.png`,
    // newAffordablePlanBG900Webp: `${productsAssetsPath}/affordable-plan-bg-900v2.webp`,
    // newAffordablePlanBG900Png: `${productsAssetsPath}/affordable-plan-bg-900v2.png`,
    // newAffordablePlanBG768Webp: `${productsAssetsPath}/affordable-plan-bg-768v2.webp`,
    // newAffordablePlanBG768Png: `${productsAssetsPath}/affordable-plan-bg-768v2.png`,
    // newAffordablePlanBG500Webp: `${productsAssetsPath}/affordable-plan-bg-500v2.webp`,
    // newAffordablePlanBG500Png: `${productsAssetsPath}/affordable-plan-bg-500v2.png`,
    // newAffordablePlanBG320Webp: `${productsAssetsPath}/affordable-plan-bg-320v2.webp`,
    // newAffordablePlanBG320Png: `${productsAssetsPath}/affordable-plan-bg-320v2.png`,

    audioIcon: `${productsAssetsPath}/audio-icon.svg`,
    fontIcon: `${productsAssetsSVGPath}/font.svg`,
    imageIcon: `${productsAssetsPath}/image-icon.svg`,
    aiImageIcon: `${productsAssetsPath}/ai-image-icon.svg`,
    vectorIcon: `${productsAssetsPath}/vector-icon.svg`,
    videoIcon: `${productsAssetsPath}/video-icon.svg`,
    aiIcon: `${productsAssetsPath}/ai-icon.svg`,

    audioThumbnailWebp: `${productsAssetsPath}/audio-320.webp`,
    audioThumbnail: `${productsAssetsPath}/audio-320.png`,
    audioThumbnailExtWebp: `${productsAssetsPath}/audio-ext-320.webp`,
    audioThumbnailExt: `${productsAssetsPath}/audio-ext-320.png`,
    imageThumbnailWebp: `${productsAssetsPath}/image-320.webp`,
    imageThumbnail: `${productsAssetsPath}/image-320.png`,
    imageExtThumbnailWebp: `${productsAssetsPath}/image-ext-300.webp`,
    imageExtThumbnail: `${productsAssetsPath}/image-ext-300.png`,
    aiImageThumbnailWebp: `${productsAssetsPath}/aiImage-320.webp`,
    aiImageThumbnail: `${productsAssetsPath}/aiImage-320.png`,
    aiImageExtThumbnailWebp: `${productsAssetsPath}/aiImage-ext-300.webp`,
    aiImageExtThumbnail: `${productsAssetsPath}/aiImage-ext-300.png`,
    videoThumbnailWebp: `${productsAssetsPath}/video-320.webp`,
    videoThumbnail: `${productsAssetsPath}/video-320.png`,
    videoExtThumbnailWebp: `${productsAssetsPath}/video-ext-300.webp`,
    videoExtThumbnail: `${productsAssetsPath}/video-ext-300.png`,
    fontThumbnailWebp: `${productsAssetsPath}/font-320.webp`,
    fontThumbnail: `${productsAssetsPath}/font-320.png`,
    fontThumbnailExtWebp: `${productsAssetsPath}/font-ext-300.webp`,
    fontThumbnailExt: `${productsAssetsPath}/font-ext-300.png`,
    tickyellow: `${productsAssetsPath}/tick-yellow.svg`,
    tick: `${productsAssetsSVGPath}/tick.svg`,

    newAffordablePlanEnterpriseOneWebp: `${productsAssetsPath}/enterprise-1.webp`,
    newAffordablePlanEnterpriseOne: `${productsAssetsPath}/enterprise-1.png`,
    newAffordablePlanEnterpriseTwoWebp: `${productsAssetsPath}/enterprise-2.webp`,
    newAffordablePlanEnterpriseTwo: `${productsAssetsPath}/enterprise-2.png`,
    newAffordablePlanEnterpriseThreeWebp: `${productsAssetsPath}/enterprise-3.webp`,
    newAffordablePlanEnterpriseThree: `${productsAssetsPath}/enterprise-3.png`,
    newAffordablePlanEnterpriseFourWebp: `${productsAssetsPath}/enterprise-4.webp`,
    newAffordablePlanEnterpriseFour: `${productsAssetsPath}/enterprise-4.png`,
    newAffordablePlanEnterpriseFiveWebp: `${productsAssetsPath}/enterprise-5.webp`,
    newAffordablePlanEnterpriseFive: `${productsAssetsPath}/enterprise-5.png`,
    newAffordablePlanEnterpriseSixWebp: `${productsAssetsPath}/enterprise-6.webp`,
    newAffordablePlanEnterpriseSix: `${productsAssetsPath}/enterprise-6.png`,
    newAffordablePlanEnterpriseSevenWebp: `${productsAssetsPath}/enterprise-7.webp`,
    newAffordablePlanEnterpriseSeven: `${productsAssetsPath}/enterprise-7.png`,

    arrowRightLine: `${productsAssetsSVGPath}/arrow-right-line.svg`,
};
