import checkIsServer from './checkIsServer';
import getHostName from './getHostName';
import redirectTo from './redirectTo';

const redirectToSiteWithSubdomain = (ctx) => {
    if (checkIsServer(ctx)) {
        const hostName = getHostName(ctx);
        const subdomain = hostName.split('.')[0];

        if (subdomain === '123rf') {
            if (checkIsServer(ctx)) {
                redirectTo(ctx.res, `https://www.${hostName}${ctx.asPath}`);
            } else {
                window.location.href = `https://www.${hostName}${window.location.pathname}`;
            }
            return true;
        }
    }
    return false;
};

export default redirectToSiteWithSubdomain;