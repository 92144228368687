import { PRODUCTS_IMAGES as images } from 'config/images/products';

const getPopularBrands = (isTabletView) => {
    return {
        accenture: {
            id: 'accenture-logo',
            image: images.accentureLogo,
            imageWebp: images.accentureLogoWebp,
            height: isTabletView ? 51.35 : 40,
            width: isTabletView ? 192.56 : 150,
            alt: 'accenture',
        },
        bayer: {
            id: 'bayer-logo',
            image: images.bayerLogo,
            imageWebp: images.bayerLogoWebp,
            height: isTabletView ? 63 : 40,
            width: isTabletView ? 67 : 42.54,
            alt: 'bayer',
        },
        merck: {
            id: 'merck-logo',
            image: images.merckLogo,
            imageWebp: images.merckLogoWebp,
            height: isTabletView ? 45.54 : 40,
            width: isTabletView ? 155.41 : 136.51,
            alt: 'merck',
        },
        unilever: {
            id: 'unilever-logo',
            image: images.unileverLogo,
            imageWebp: images.unileverLogoWebp,
            height: isTabletView ? 62 : 40,
            width: isTabletView ? 57 : 36.76,
            alt: 'unilever',
        },
        united: {
            id: 'united-logo',
            image: images.unitedLogo,
            imageWebp: images.unitedLogoWebp,
            height: isTabletView ? 44.75 : 40,
            width: isTabletView ? 246.61 : 220.44,
            alt: 'united',
        },
        ogilvy: {
            id: 'ogilvy-logo',
            image: images.ogilvyLogo,
            imageWebp: images.ogilvyLogoWebp,
            height: isTabletView ? 44.15 : 40,
            width: isTabletView ? 111.84 : 101.33,
            alt: 'ogilvy',
        },
        chevron: {
            id: 'chevron-logo',
            image: images.chevronLogo,
            imageWebp: images.chevronLogoWebp,
            height: isTabletView ? 64 : 40,
            width: isTabletView ? 60 : 37.5,
            alt: 'chevron',
        },
        master: {
            id: 'master-logo',
            image: images.masterCardLogo,
            imageWebp: images.masterCardLogoWebp,
            height: isTabletView ? 63.05 : 40,
            width: isTabletView ? 101.46 : 64.38,
            alt: 'master card',
        },
        loreal: {
            id: 'loreal-logo',
            image: images.lorealLogo,
            imageWebp: images.lorealLogoWebp,
            height: isTabletView ? 35.53 : 40,
            width: isTabletView ? 146.04 : 164.44,
            alt: 'loreal paris',
        },
        accor: {
            id: 'accor-logo',
            image: images.accorLogo,
            imageWebp: images.accorLogoWebp,
            height: isTabletView ? 64 : 40,
            width: isTabletView ? 80 : 50,
            alt: 'accor',
        },
        marriott: {
            id: 'marriott-logo',
            image: images.marriottLogo,
            imageWebp: images.marriottLogoWebp,
            height: isTabletView ? 43 : 40,
            width: isTabletView ? 150 : 139.53,
            alt: 'marriott bonvoy',
        },
        google: {
            id: 'google-logo',
            image: images.googleLogo,
            imageWebp: images.googleLogoWebp,
            height: 40,
            width: 112.68,
            alt: 'google',
        },
        grab: {
            id: 'grab-logo',
            image: images.grabLogo,
            imageWebp: images.grabLogoWebp,
            height: 40,
            width: 114,
            alt: 'grab',
        },
        sourceNext: {
            id: 'source-next-logo',
            image: images.sourceNextLogo,
            imageWebp: images.sourceNextLogoWebp,
            height: 40,
            width: 209,
            alt: 'source next',
        },
    };
};

export default getPopularBrands;
