import { useState } from 'react';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';

// Pre-defined Components
import { COUNTRIES_WITH_TAX, CREDITS_ONLY_PAGE_ORIGIN as creditsPO } from 'config/products';

// Sub-Components
import HeaderGroup from './HeaderGroup';
import FooterGroup from './FooterGroup';

const PricingCard = (props) => {
    const {
        cardType,
        isLangKR,
        CommonDetails,
        WindowDimension,
        children,
        flexOrder,
        isPlusBusinessActive,
    } = props;
    const { countryOrigin, pageOrigin, lang } = CommonDetails;
    const { isMobileView, isTabletView, isDesktopView, width } = WindowDimension;
    const device =
        width > 1400 ? 'desktopXL' : isDesktopView ? 'desktop' : isTabletView ? 'tablet' : 'mobile';
    const countryWithTax = COUNTRIES_WITH_TAX.includes(countryOrigin);

    const LONG_HEADER_TRANSLATION_LANGS = 
        width >= 1200 && width <= 1400 ? ['EN', 'DE', 'ES', 'IT', 'FR', 'HU', 'NL', 'PL', 'PT', 'RU', 'TR'] : ['FR', 'HU', 'PL', 'RU'];
    const headerHeight = LONG_HEADER_TRANSLATION_LANGS.includes(lang.toUpperCase()) ? '96px' : '78px';

    const pricingCardSize = {
        enterprise: {
            desktopXL: '340px',
            desktop: '290px',
            tablet: '700px',
            mobile: '100%',
        },
        subs: {
            desktopXL: '340px',
            desktop: '290px',
            tablet: '342px',
            mobile: '100%',
        },
        credits: {
            desktopXL: !creditsPO.includes(pageOrigin) ? '340px' : '500px',
            desktop: !creditsPO.includes(pageOrigin) ? '290px' : '500px',
            tablet: !creditsPO.includes(pageOrigin) ? '342px' : '500px',
            mobile: '100%',
        },
        plus: {
            desktopXL: '340px',
            desktop: '290px',
            tablet: '342px',
            mobile: '100%',
        },
        plus_business: {
            desktopXL: '340px',
            desktop: '290px',
            tablet: '342px',
            mobile: '100%',
        },
    };

    // Local states
    const [subscribeNowClicked, setSubscribeNowClicked] = useState(undefined);
    const [buyNowClicked, setBuyNowClicked] = useState(undefined);
    const [contactUsClicked, setContactUsClicked] = useState(undefined);

    const handlePricingButtonClick = (type) => {
        if (
            type === 'enterprise' ||
            (type === 'plus' && isPlusBusinessActive) ||
            (type === 'plus_business' && !isPlusBusinessActive)
        )
            return setContactUsClicked((prev) => !prev);
        if (type === 'subs' || type === 'plus' || type === 'plus_business')
            return setSubscribeNowClicked((prev) => !prev);
        return setBuyNowClicked((prev) => !prev);
    };

    return (
        <div className="PricingCard__wrapper">
            <div className="PricingCard__cardHeader">
                <HeaderGroup cardType={cardType} {...props} />
            </div>
            <div className="PricingCard__cardBody">
                <div className="PricingCard__upperGroup">
                    {children({
                        isPricingCardButtonClicked:
                            cardType === 'enterprise' ||
                            (cardType === 'plus' && isPlusBusinessActive) ||
                            (cardType === 'plus_business' && !isPlusBusinessActive)
                                ? contactUsClicked
                                : cardType === 'subs' ||
                                  cardType === 'plus' ||
                                  cardType === 'plus_business'
                                ? subscribeNowClicked
                                : buyNowClicked,
                        setIsPricingCardButtonClicked:
                            cardType === 'enterprise' ||
                            (cardType === 'plus' && isPlusBusinessActive) ||
                            (cardType === 'plus_business' && !isPlusBusinessActive)
                                ? setContactUsClicked
                                : cardType === 'subs' ||
                                  cardType === 'plus' ||
                                  cardType === 'plus_business'
                                ? setSubscribeNowClicked
                                : setBuyNowClicked,
                        isLangKR,
                    })}
                </div>
                <FooterGroup
                    cardType={cardType}
                    handlePricingButtonClick={handlePricingButtonClick}
                    {...props}
                />
                <span className="PricingCard__taxesMayApply">
                    {intl.get('PRODUCTS.ChoosePlan_TaxesMayApply')}
                </span>
            </div>

            <style jsx>
                {`
                    .PricingCard {
                        &__wrapper {
                            flex-basis: auto;
                            position: relative;
                            width: ${pricingCardSize[cardType][device]};
                            max-width: ${isMobileView ? '400px' : 'unset'};
                            min-height: auto;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: flex-start;
                            padding: 0px;
                            z-index: 1;
                            background: transparent;
                            border-radius: 8px;
                            overflow: hidden;
                            order: ${flexOrder};
                        }

                        &__cardHeader {
                            display: flex;
                            padding: 16px 24px;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            align-self: stretch;
                            background: #f9f9f9;
                            min-height: ${headerHeight};
                        }

                        &__cardBody {
                            position: relative;
                            width: 100%;
                            height: 100%;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: ${isDesktopView || isTabletView
                                ? 'space-between'
                                : 'flex-start'};
                            padding: ${countryWithTax ? '16px 24px 26px 24px' : '16px 24px'};
                            background: #ffffff;
                        }

                        &__upperGroup {
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                        }

                        &__taxesMayApply {
                            display: ${COUNTRIES_WITH_TAX.includes(countryOrigin)
                                ? 'block'
                                : 'none'};
                            position: absolute;
                            bottom: 0;
                            right: 0;
                            font-size: 10px;
                            font-weight: 500;
                            line-height: 0.75rem;
                            text-align: right;
                            margin-right: 10px;
                            margin-bottom: 5px;
                        }
                    }
                `}
            </style>
        </div>
    );
};

PricingCard.propTypes = {
    cardType: PropTypes.string.isRequired,
    isLangKR: PropTypes.bool.isRequired,
    CommonDetails: PropTypes.objectOf(PropTypes.shape).isRequired,
    WindowDimension: PropTypes.objectOf(PropTypes.shape).isRequired,
    children: PropTypes.func.isRequired,
    flexOrder: PropTypes.number,
    isPlusBusinessActive: PropTypes.bool,
};

PricingCard.defaultProps = {
    flexOrder: 1,
    isPlusBusinessActive: false,
};

export default PricingCard;
