import cx from 'classnames';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
import { Button } from '123rf-ui-core/components';

import { CREDITS_ONLY_PAGE_ORIGIN as creditsPO } from 'config/products';
import toSentenceCase from 'helpers/shared/toSentenceCase';

const FooterGroup = (props) => {
    const {
        cardType,
        handlePricingButtonClick,
        showPlusFreeTrial,
        isPlusFreeTrialSelected,
        CommonDetails,
        WindowDimension,
        User,
        isPlusBusinessActive,
    } = props;
    const { pageOrigin } = CommonDetails;
    const { isTabletView } = WindowDimension;
    const { userBalance } = User;
    const isSubsPricingCard = cardType === 'subs';
    const isPlusPricingCard = cardType === 'plus';
    const isPlusBusinessPricingCard = cardType === 'plus_business';
    const isCreditsPricingCard = cardType === 'credits';
    const isEnterprisePricingCard = cardType === 'enterprise';

    const isPlusPlanActive = userBalance?.plus > 0;

    const getLabel = () => {
        if (isSubsPricingCard) return 'PRODUCTS.SubscribeNow';
        if (isEnterprisePricingCard) return 'SHARED.ContactUs';

        if (isPlusPricingCard || isPlusBusinessPricingCard) {
            if (isPlusPlanActive) {
                if (
                    (isPlusPricingCard && !isPlusBusinessActive) ||
                    (isPlusBusinessPricingCard && isPlusBusinessActive)
                ) {
                    return 'PRODUCTS.YourCurrentPlan';
                }
                return 'SHARED.ContactUs';
            }
            if (showPlusFreeTrial && isPlusPricingCard && isPlusFreeTrialSelected) {
                return 'PRODUCTS.StartFreeTrial';
            }
            return 'PRODUCTS.SubscribeNow';
        }

        return 'PRODUCTS.ChoosePlan_BuyNow';
    };

    return (
        <div className="FooterGroup__wrapper">
            <Button
                id={
                    isEnterprisePricingCard
                        ? 'contact-us-enterprise'
                        : isPlusPlanActive &&
                          ((isPlusPricingCard && isPlusBusinessActive) ||
                              (isPlusBusinessPricingCard && !isPlusBusinessActive))
                        ? 'contact-us-plus'
                        : isSubsPricingCard
                        ? 'subscribe-now-splan'
                        : isPlusPricingCard
                        ? 'subscribe-now-plus'
                        : isPlusBusinessPricingCard
                        ? 'subscribe-now-plusb'
                        : 'buy-now-credits'
                }
                data-testid={
                    isEnterprisePricingCard
                        ? 'contact-us-enterprise'
                        : isPlusPlanActive &&
                          ((isPlusPricingCard && isPlusBusinessActive) ||
                              (isPlusBusinessPricingCard && !isPlusBusinessActive))
                        ? 'contact-us-plus'
                        : isSubsPricingCard
                        ? 'subscribe-now-splan'
                        : isPlusPricingCard
                        ? 'subscribe-now-plus'
                        : isPlusBusinessPricingCard
                        ? 'subscribe-now-plusb'
                        : 'buy-now-credits'
                }
                name={
                    isEnterprisePricingCard
                        ? 'contact-us-enterprise'
                        : isPlusPlanActive &&
                          ((isPlusPricingCard && isPlusBusinessActive) ||
                              (isPlusBusinessPricingCard && !isPlusBusinessActive))
                        ? 'contact-us-plus'
                        : isSubsPricingCard
                        ? 'subscribe-now-splan'
                        : isPlusPricingCard
                        ? 'subscribe-now-plus'
                        : isPlusBusinessPricingCard
                        ? 'subscribe-now-plusb'
                        : 'buy-now-credits'
                }
                color={
                    isPlusBusinessPricingCard ||
                    (isCreditsPricingCard && creditsPO.includes(pageOrigin))
                        ? 'primary'
                        : 'secondary'
                }
                label={toSentenceCase(intl.get(getLabel()))}
                disabled={
                    isPlusPlanActive &&
                    ((isPlusPricingCard && !isPlusBusinessActive) ||
                        (isPlusBusinessPricingCard && isPlusBusinessActive))
                }
                onClick={() => handlePricingButtonClick(cardType)}
                variant="modern"
                className={cx('FooterGroup__button', {
                    'FooterGroup__button--auto': isEnterprisePricingCard && isTabletView,
                })}
            />

            <style jsx>
                {`
                    .FooterGroup {
                        &__wrapper {
                            width: 100%;
                            height: auto;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: space-between;
                            margin-top: 20.5px;
                        }
                    }
                `}
            </style>
            <style jsx global>
                {`
                    .FooterGroup {
                        &__button {
                            min-width: 240px !important;
                            min-height: 44px !important;
                            width: 100%;
                            padding: 10px;

                            &--auto {
                                width: auto !important;
                            }

                            &:disabled {
                                opacity: 100% !important;
                                border: initial !important;
                                background: #e0e0e0 !important;
                                color: #212121 !important;
                            }
                        }
                    }
                `}
            </style>
        </div>
    );
};

FooterGroup.propTypes = {
    cardType: PropTypes.string.isRequired,
    handlePricingButtonClick: PropTypes.func.isRequired,
    showPlusFreeTrial: PropTypes.bool.isRequired,
    isPlusFreeTrialSelected: PropTypes.bool.isRequired,
    CommonDetails: PropTypes.objectOf(PropTypes.shape).isRequired,
    WindowDimension: PropTypes.objectOf(PropTypes.shape).isRequired,
    User: PropTypes.objectOf(PropTypes.shape).isRequired,
    isPlusBusinessActive: PropTypes.bool,
};

FooterGroup.defaultProps = {
    isPlusBusinessActive: false,
};

export default FooterGroup;
