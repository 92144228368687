/* eslint-disable import/no-extraneous-dependencies */
// products.global.js
import css from 'styled-jsx/css';

export default css.global`
    .Header__container {
        display: flex !important;
        align-items: center !important;
    }

    .Modal {
        z-index: 9999 !important;
    }

    .Radio {
        display: flex !important;
    }

    .Badge__wrapper--selected:hover::after {
        display: none;
    }

    .AgreementBanner__wrapper {
        z-index: 1080 !important;
    }

    .pricing__buttonBuyNow {
        height: auto;
        min-height: 42px;
        width: 100%;
        border-radius: 22px !important;
        border: none;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    }

    .pricingNew__buttonBuyNow {
        height: auto;
        min-height: 44px;
        width: auto;
        min-width: 157px;
        color: #212121 !important;
    }

    .pricing__choosePlanSubTitleKR {
        padding-top: 24px;
        font-size: 14px;
        line-height: 1.25rem;
        text-align: center;
    }

    .font-weight-600 {
        font-weight: 600;
    }

    .font-weight-700 {
        font-weight: 700;
    }

    .SubscriptionPlan__truePrice {
        text-decoration: line-through;
        color: #e91d35;
    }

    .SubscriptionPlan__discountedPrice {
        white-space: nowrap;
    }

    .CommonLink {
        color: #2298ca !important;
        border-bottom: 1px dotted #2298ca !important;

        &:hover,
        &:active {
            color: #2298ca !important;
            border-bottom: 1px solid #2298ca !important;
        }
    }

    @-moz-document url-prefix() {
        .Radio__label {
            padding-left: 10px;
            margin-left: 16px;
        }
    }
`;
