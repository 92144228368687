import {
    CHECKOUT_SUB_PATHS,
    LOGIN_PATH,
    PRODUCTS_PATH,
} from 'config/constants';
import { GLOBAL_LANG } from 'config/cookies';
import { SUPPORTED_HREF_LANGS as supportedLangs } from 'config/global';
import checkIsServer from './checkIsServer';
import getSiteLanguage from './getSiteLanguage';
import redirectTo from './redirectTo';

const redirectToLangSiteByCountry = (props) => {
    const { ctx, cookies, locale, hostName } = props;
    const countryCode = locale?.country?.code || '';
    const lang = cookies[GLOBAL_LANG];

    if (
        checkIsServer(ctx) &&
        !CHECKOUT_SUB_PATHS.includes(ctx?.pathname) &&
        getSiteLanguage(hostName) === 'en' &&
        !ctx?.query?.co &&
        !lang &&
        countryCode
    ) {
        if (countryCode === 'CN') {
            redirectTo(ctx.res, `https://gb.123rf.com${ctx.asPath}`);
            return true;
        }

        const langByCountry =
            supportedLangs.filter(
                (sl) => sl.lang === countryCode?.toLowerCase() && countryCode !== 'GB',
            )[0] || '';

        if (langByCountry) {
            redirectTo(ctx.res, `https://${langByCountry.lang}.123rf.com${ctx.asPath}`);
            return true;
        }
    }
    return false;
};

export default redirectToLangSiteByCountry;