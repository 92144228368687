import { useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

const CustomSwitchHorizontal = (props) => {
    const { on, onText, offText, onSwitchChange, WindowDimension } = props;
    const { width } = WindowDimension;

    const onRef = useRef();
    const offRef = useRef();

    const [sliderHeight, setSliderHeight] = useState(34);
    const [sliderTop, setSliderTop] = useState(0);

    useEffect(() => {
        setSliderHeight(on ? onRef?.current?.clientHeight : offRef?.current?.clientHeight);
        setSliderTop(on ? onRef?.current?.offsetTop : offRef?.current?.offsetTop);
    }, [on, onRef, offRef, width]);

    return (
        <>
            <div className="CustomSwitchHorizontal__container">
                <button
                    ref={onRef}
                    type="button"
                    className={cx('CustomSwitchHorizontal__tab', {
                        'CustomSwitchHorizontal__tab--selected': on,
                    })}
                    onClick={() => onSwitchChange()}
                >
                    {onText}
                </button>
                <button
                    ref={offRef}
                    type="button"
                    className={cx('CustomSwitchHorizontal__tab', {
                        'CustomSwitchHorizontal__tab--selected': !on,
                    })}
                    onClick={() => onSwitchChange()}
                >
                    {offText}
                </button>
                <div className="CustomSwitchHorizontal__slider" />
            </div>

            <style jsx>
                {`
                    .CustomSwitchHorizontal {
                        &__container {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            align-self: stretch;

                            border-radius: 8px;
                            border: 1px solid #e0e0e0;
                            background: transparent;

                            position: relative;
                            z-index: 1;
                        }

                        &__tab {
                            display: flex;
                            padding: 8px 16px;
                            justify-content: flex-start;
                            text-align: start;
                            align-items: center;
                            gap: 10px;
                            align-self: stretch;
                            min-height: 34px;

                            border-radius: 8px;

                            color: #a3a3a3;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 1.125rem;

                            border: none;
                            background: transparent;
                            margin: 0;
                            cursor: pointer;
                            -webkit-appearance: none;
                            -moz-appearance: none;

                            &--selected {
                                color: #212121;
                            }
                        }

                        &__slider {
                            position: absolute;
                            width: 100%;
                            height: ${sliderHeight}px;
                            border-radius: 8px;
                            border: 1.5px solid #ffd44d;
                            background: #fff6dc;
                            top: ${sliderTop}px;
                            transition: top 0.2s ease, height 0.2s ease;
                            z-index: -1;
                        }
                    }
                `}
            </style>
        </>
    );
};

CustomSwitchHorizontal.propTypes = {
    on: PropTypes.bool.isRequired,
    onText: PropTypes.node.isRequired,
    offText: PropTypes.node.isRequired,
    onSwitchChange: PropTypes.func,
    WindowDimension: PropTypes.objectOf(PropTypes.shape).isRequired,
};

CustomSwitchHorizontal.defaultProps = {
    onSwitchChange: () => null,
};

export default CustomSwitchHorizontal;
